import './GuardedImageTile.scss'
import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export interface ConstrainedSize {
  maxWidth: string
  maxHeight: string
}

function secureImage(brokenClass: string) {
  return (e: SyntheticEvent) => {
    const target = e.target as HTMLImageElement
    if (target.parentElement) {
      target.parentElement.classList.add(brokenClass)
    }
  }
}

function swapImage(img: HTMLImageElement, constrainedSize = false) {
  if (img.parentElement) {
    void img.src
    img.parentElement.style.backgroundImage = `url(${img.src})`
    img.parentElement.classList.add('guarded__image--loaded')
    if (img.parentElement.classList.contains('guarded__image--broken')) {
      img.parentElement.classList.remove('guarded__image--broken')
    }
    if (constrainedSize) {
      /**
       * first accessing the properties of the Image does not work,
       * which is probably a bug in some strange constellation
       * in iOS Safari itself.
       */
      void img.width
      void img.height
      img.parentElement.style.width = `${img.width}px`
      img.parentElement.style.height = `${img.height}px`
    }
  }
}

interface Props {
  imageUrl: string
  className?: string
  constrainedSize?: ConstrainedSize
  alt?: string
  imageFit?: 'contain' | 'cover'
  backgroundColor?: string
}

export const GuardedImageTile: React.FC<Props> = React.memo(function GuardedImageTile(props) {
  const { t } = useTranslation()
  const {
    imageUrl,
    alt,
    constrainedSize,
    className,
    imageFit = 'contain',
    backgroundColor = '#f0f0f0',
  } = props

  return (
    <div
      style={{ ...constrainedSize, backgroundColor }}
      className={clsx('guarded__image', `guarded__image__${imageFit}`, className)}
    >
      <img
        alt={alt || t('SEARCH.ARTICLELIST.ARTICLE.ALTIMGTEXT')}
        src={imageUrl}
        onLoad={(e) => swapImage(e.target as HTMLImageElement, Boolean(constrainedSize))}
        onError={secureImage('guarded__image--broken')}
      />
    </div>
  )
})
