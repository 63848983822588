import React, { useEffect } from 'react'
import { NewsCard } from '@obeta/components/lib/newscard/NewsCard'
import { ProductsSwiper } from '../articleDetailsPage/ProductsSwiper'
import styles from './NewsCardSection.module.scss'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { ApolloProvider } from '@apollo/client'
import { useNews } from './hooks/useNews'
import dayjs from 'dayjs'
import de from 'dayjs/locale/de'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { useStartPageData } from '@obeta/data/lib/hooks/useStartPageDataProvider'

type Props = { excludedIds?: string[] }

export const NewsCardSection: React.FC<Props> = (props) => {
  const apolloClient = useStrapiGql()

  return (
    <ApolloProvider client={apolloClient}>
      <NewsCardSectionView {...props} />
    </ApolloProvider>
  )
}

export const NewsCardSectionView: React.FC<Props> = ({ excludedIds = [] }) => {
  const { news: cachedNews, setNews: setCachedNews, timeLimit, setTimeLimit } = useStartPageData()
  const timeLimitExpired = timeLimit && timeLimit < dayjs(new Date()).unix()

  useEffect(() => {
    if (timeLimit && !timeLimitExpired) return
    setTimeLimit(dayjs(new Date()).add(1, 'hours').unix())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { news } = useNews({
    config: {
      filters: {
        pinned: {
          eq: true,
        },
      },
    },
    hasCachedNewsAndIsNotExpired: !timeLimitExpired && cachedNews.length > 0,
  })

  useEffect(() => {
    if (news.length > 0 && cachedNews.length === 0) {
      setCachedNews(news)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news])

  const { mobile, tabletAll } = useBreakpoints()

  const getElementsPerRow = (news: INewsCard[]) => {
    const elementsPerRow = mobile ? 1 : tabletAll ? 2 : 3

    return elementsPerRow > news.length ? news.length : elementsPerRow
  }

  const filteredNews = cachedNews.filter((news) => !excludedIds.includes(news.id))

  return (
    <div className={styles['news-card-container']}>
      <ProductsSwiper
        closeNavigationButtons={mobile}
        cardWrapperClassName={styles.cardWrapper}
        withNavigateButtons
        isSliderLoop={false}
        elementsPerRow={getElementsPerRow(filteredNews)}
        productElements={filteredNews.map((p) => (
          <NewsCard {...p} startDate={formatDateAsMonthNameAndYear(p.startDate)} key={p.id} />
        ))}
      />
    </div>
  )
}

export function formatDateAsMonthNameAndYear(startDateRaw: string): string {
  return dayjs(startDateRaw).locale(de).format('MMMM YYYY')
}
